import '../core/wdyr';

import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/SEO/seo';
import GameContainer from '../containers/game-container';

function IndexPage(props) {
    return (
        <Layout>
            <SEO pageTitle="Home"/>
            <GameContainer location={ props.location }/>
        </Layout>
    );
}

IndexPage.propTypes = {
    location: PropTypes.object,
};

export default IndexPage;
